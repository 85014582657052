import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InfoComponent } from './component/info-page/info/info.component';
import { LoginAsComponent } from './content/pages/auth/login-as/login-as.component';
import { ResetPasswordComponent } from './content/pages/auth/reset-password/reset-password.component';
import { VerifyAccountComponent } from './content/pages/auth/verify-account/verify-account.component';
const routes: Routes = [
	{
		path: '',
		loadChildren: 'app/content/pages/pages.module#PagesModule'
	},
	{ path: 'verify-admin', component: LoginAsComponent },
	{ path: 'app/:token', component: InfoComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'verify-account', component: VerifyAccountComponent },
	{
		path: '**',
		redirectTo: '404',
		pathMatch: 'full'
	},
	
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes)
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
