import { environment } from '../environments/environment';

// export const base_url = 'https://prodapi.aed365.com/aed365-backend-phpadminapi/public/api/';
export const base_url = environment.base_url;
//export const base_url = environment.base_url;
//export const base_url = 'http://dev.algoworks.com/aed365-backend-phpadminapi/public/api/';
//export const base_url = 'http://192.168.9.29/aed365-backend-adminpanelapi/public/api/';

export const changePassword = base_url + 'profile/change-password';
export const updateProfile = base_url + 'profile/update';
export const getProfile = base_url + 'profile/me';
export const getOdo = base_url + '/user/odo';
export const getMakeList = base_url + '/user/getCarModelAndMake';
export const carPreference = base_url + '/user/car_preferences';
export const getCities = base_url + '/user/getCities';
export const googleLocationKey = environment.googleLocationKey;
export const googleLocationUrl = 'https://maps.googleapis.com/maps/api/geocode/json';

export const urls: any = {
	addAedDependency: base_url + 'aed/add-aed-dependency',
	aedList: base_url + 'aed/list',
	viewAed: base_url + 'aed/view',
	oldAedParts: base_url + 'aed/parts/old/list',
	deleteAed: base_url + 'aed/delete',
	addAed: base_url + 'v3/aed/add',
	updateAed: base_url + 'v3/aed/update',
	trashList: base_url + 'aed/trash/list',
	subscriptionList: base_url + 'subscription/list',
	addDependency: base_url + 'subscription/add-dependency',
	viewSubscription: base_url + 'subscription/view',
	addLibrary: base_url + 'library/edit',
	generaterx: base_url + 'aed/generate-rx',
	responderAdd: base_url + 'responder/add',
	responderList: base_url + 'responder/list',
	userList: base_url + 'user/list',
	siteList: base_url + 'site/list',
	certificationProvider: base_url + 'responder/dependency',
	viewResponder: base_url + 'responder/fetch/{responder_id}',
	deleteResponder: base_url + 'responder/delete/{responder_id}',
	editResponder: base_url + 'responder/update',
	companyList: base_url + 'company/list',
	AEDRequestsList: base_url + 'aed/custom/list',
	viewCustomAED: base_url + 'aed/custom/view',
	acceptCustomAED: base_url + 'aed/custom/accept',
	rejectCustomAED: base_url + 'aed/custom/reject',
	verifyAdminUser: base_url + 'admin/authenticate',
	userManualList: base_url + 'user_manual/list',
	userManualView: base_url + 'user_manual/view',
	getUserManualById: base_url + 'user_manual/get-user-manual-aed',
	addAedInspectorDependency: base_url + 'aed/add-aed-inspector-dependency',
	accessoryReplacementRequestList: base_url + 'aed/replacement/list',
	accessoryReplacementRequestView: base_url + 'aed/replacement/view',
	accessoryReplacementComplete: base_url + 'aed/accessory-update-status',
	resellerList: base_url + 'reseller/list',
	resellerAdd: base_url + 'reseller/add',
	resellerEdit: base_url + 'reseller/edit',
	resellerDelete: base_url + 'reseller/delete',
	resellerView: base_url + 'reseller/view',
	resellerRole: base_url + 'reseller/role',
	getPermissions: base_url + 'reseller/get-permissions',
	setPermissions: base_url + 'reseller/set-permissions',
	userPermission: base_url + 'reseller/user-permissions',
	dashboard: base_url + 'reseller/dashboard',
	mapView: base_url + 'reseller/map',
	getFreeTrials: base_url + 'free-trial/list',
	redeemFreetrial: base_url + 'free-trial/redeem',
	assignedFreeTrial: base_url + 'free-trial/assigned',
	requestMorefreeTrial: base_url + 'free-trial/request',
	subscriptionListPlan: base_url + 'subscription/list-plan',
	requestPlan: base_url + 'subscription/request-plan',
	addAedToSubscription: base_url + 'subscription/add-aed',
	editAedToSubscription: base_url + 'subscription/edit-aed',
	myProgram: base_url + 'subscription/assigned',
	postOrderSummary:base_url + 'subscription/summary',
	postFreeOrderCheckout: base_url + 'subscription/free-trial',
	sendPaymentUpdate: base_url + 'subscription/save-order',
	viewAedQr:base_url + 'aed/view-qrcode',
	renewSubscription: base_url+'subscription/renew',
	partsExpiredList: base_url +'aed/parts/list',
	viewPartExpired: base_url + 'aed/parts/detail',
	updatePartExpiredStatus: base_url + 'aed/parts/update-part-expired-status',
	incidentList: base_url + 'incidents/list',
	viewIncident: base_url + 'incidents/detail',
	subscriptionReports: base_url + 'subscription/report',
	reminderReports: base_url + 'aed/heartsine-reminder-report',
	restoreAed: base_url+ 'aed/trash/restore',
	plansInvoicePdf: base_url+ 'subscription/invoice',
	inspectionList: base_url + 'inspection/list',
	viewInspection: base_url + 'inspection/detail',
	lastVisitCount: base_url + 'last-visit/count',
	planListBefore90Days: base_url + 'subscription/get-plans-before-ninety-days-expiry',
	AEDTypeList: base_url + 'rms/aed-types',
	activateRMS: base_url + 'rms/activate-rms',
	downloadRMSReport: base_url + 'rms/reports',
	manageRenewReportList: base_url + 'subscription/renew-list',
	manageRenewReportDetail: base_url + 'subscription/renew-detail',
	updateRenewReportStatus: base_url + 'subscription/renew',
	updateRejectStatus: base_url + 'subscription/reject-subscription-request',
	downloadSampleFile: base_url + 'aed/sample-file',
	uploadBulkFile: base_url + 'aed/bulk-upload',
	imageLibrary: base_url + 'aed/fetch-model-image',
	planList: base_url + 'subscription/get-purchased-plans-without-aeds',
	downloadOutofServiceAedList: base_url + 'aed/out-of-service',
	updatePartExpiringStatus: base_url + 'aed/parts/update-part-expiring-status',
	activateZoll:base_url + 'zoll/activate',
	downloadZollReport:base_url + 'zoll/reports',
};
