import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilsService } from '../../../app/content/pages/components/_core/utils/http-utils.service';
import { base_url } from '../../global';

@Injectable()
export class UserService {
	API_URL:any=base_url;
	public xhr = null;
	public apiResponse: boolean = true;
	constructor(private http: HttpClient,private httpUtils: HttpUtilsService) {}

	public listuserrequests(params): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http
			.post(this.API_URL + 'user-request/list' , params , {headers: headers}
            );
	}

	public viewrequests(id): Observable<any> {
		let formData = {
			user_id: id
		};
	  return this.http.post(this.API_URL+'user-request/view',formData);
	}

	public acceptrequest(formData): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL+'user-request/accept',
		formData, {headers: headers});
	}
	public viewuser(params): Observable<any> {
		this.showLoader(true);
		this.apiResponse = false;
		const headers = this.httpUtils.getHTTPHeaders();
		return new Observable(observer => {
		//  this.http.post(this.API_URL + 'user/list', params, {headers: headers})
		 this.xhr = this.http.post(this.API_URL + 'user/list', params, {headers: headers})
		 .subscribe(
			response => {
				//	console.log('response', response);
					observer.next(response);
					this.showLoader(false);
			} );
	});
}
	public viewcompany(): Observable<any>{
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'company/list', {
			page: 1,
			show_all: 1,
			search: ""
		} , {headers: headers});
	}
	public viewcompanies(id): Observable<any>{
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'company/list', {
			page: 1,
			show_all: 1,
			company_type: id,
			search: ""
		} , {headers: headers});
	}
	public selectcompany(id): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'user/list', {
			page: 1,
			search: "",
			company_id: id,
		} , {headers: headers});
	}
	// public viewrole(): Observable<any>{
	// 	const headers = this.httpUtils.getHTTPHeaders();
	// 	return this.http.post(this.API_URL + 'master/user-role/list', '' , {headers: headers});
	// }
	public viewuserpermission(): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'master/role-permission-chart', { }, {headers: headers});
	}
	public blockuser(id): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'user/change-status',{
        user_id: id,
        status: 0,
}, {headers: headers});
	}
	public unblockuser(id): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'user/change-status',{
        user_id: id,
        status: 1,
}, {headers: headers});
	}
	public adduser(formData): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'user/add-update',formData, {headers: headers});
	}
	public viewusers(id): Observable<any> {
		let formData = {
			user_id: id
		};
	  return this.http.post(this.API_URL+'user/view',formData);
	}
	public deleteUser(formData): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
	  return this.http.post(this.API_URL+'user/delete',formData);
	}

	public rejectrequest(rejectformData): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL+'user-request/reject',
		rejectformData, {headers: headers});
	}
	public viewsites(params): Observable<any> {
		this.showLoader(true);
		this.apiResponse = false;
		const headers = this.httpUtils.getHTTPHeaders();
		return new Observable(observer => {
		 this.xhr = this.http.post(this.API_URL + 'site/list', params, {headers: headers})
		 .subscribe(
			response => {
				//	console.log('response', response);
					observer.next(response);
					this.showLoader(false);
			} );
	});
}

public viewsitesfordrop(id) {
	const headers =this.httpUtils.getHTTPHeaders();
	return this.http.post(this.API_URL+'site/list',
	{company_id: id,'show_all':1},
	{headers:headers})
}

	public addSites(formData): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'site/add',formData, {headers: headers});
	}
	public editSites(formData): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
		return this.http.post(this.API_URL + 'site/update',formData, {headers: headers});
	}
	public deleteSite(formData): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
	  return this.http.post(this.API_URL+'site/delete',formData)
	}
	public deleteResponder(formData): Observable<any> {
		const headers = this.httpUtils.getHTTPHeaders();
	  return this.http.post(this.API_URL+'responder/delete',formData)
	}
	public cancelRequest() {
		       if (this.xhr && !this.xhr.closed) {
		           this.xhr.unsubscribe();
		        }
				}
	showLoader(showLoader: boolean) {
					// document.getElementById('loading').style.display = showLoader ? 'block' : 'none';
			}
			public generateRx(path): Observable<any> {
				const headers = this.httpUtils.getHTTPHeaders();
				return this.http.get(path, {headers: headers});
			}

			public getPdf(path) {
        this.showLoader(true);
        this.apiResponse = false;
        return new Observable(observer => {
            const request = this.http.get(path, { headers: this.httpUtils.getHTTPHeaders(), responseType: 'blob', observe: 'response' })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        var returnError:any;
                        var blob = new Blob([error.error], { type: 'application/json' ,endings:'native'});
                        
                        const reader = new FileReader();

                        // This fires after the blob has been read/loaded.
                        reader.addEventListener('loadend', (e) => {
                            returnError = e.srcElement['result'] || false;
                            if (returnError) {
                                returnError = JSON.parse(returnError);
                            }

                            observer.error(returnError);

                            this.showLoader(false);
                            if (returnError) {
															return returnError.message
                               // this.showError(returnError.message);
                            } else {
                               // this.showError('Unknown error occured.');
                            }
                        });

                        // Start reading the blob as text.
                        reader.readAsText(blob);
                    }
                );
        });
		}
		
		public viewaddInspectors(params): Observable<any> {
			this.showLoader(true);
			this.apiResponse = false;
			const headers = this.httpUtils.getHTTPHeaders();
			return new Observable(observer => {
			 this.xhr = this.http.post(this.API_URL + 'aed/add-aed-inspector-dependency', params, {headers: headers})
			 .subscribe(
				response => {
					//	console.log('response', response);
						observer.next(response);
						this.showLoader(false);
				} );
		});
	}
}

