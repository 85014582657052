import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AppService {
    public apiResponse: boolean = true;
    public xhr = null;
    file_keys = ['rx_file', 'user_manual', 'file'];
    allow_null_or_empty = [];

    constructor(
        private http: HttpClient,
        private toastr: ToastrService
    ) {
        // this.headers.append(localStorage.getItem('key'),localStorage.getItem('value'));
    }

    public cancelRequest() {
        if (this.xhr && !this.xhr.closed) {
            this.xhr.unsubscribe();
        }
    }

    public post(path, payload): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        return new Observable(observer => {
            this.xhr = this.http.post(path, payload, { headers: this.getHeader() })
                .subscribe(
                    response => {
                     //   console.log('response', response);
                        observer.next(response);
                        this.showLoader(false);
                    },
                    error => {
                        // let result =JSON.parse(error)
                     //   console.log("error", error);
                        observer.error(error);
                        this.showLoader(false);
                        this.toastr.error(error['error'].message, 'Error');
                        // this.showError(error.error.message);
                    }
                );
        });
    }

    public put(path, payload): Observable<any> {
        return new Observable(observer => {
            this.http.put(path, payload, { headers: this.getHeader() })
                .subscribe(
                    repsonse => { observer.next(repsonse); },
                    error => observer.error(error)
                );
        });
    }

    public get(path) {
        this.showLoader(true);
        this.apiResponse = false;
        return new Observable(observer => {
            let headers = this.getHeader();
            // headers.append('Content-Type', 'application/json');
           //  console.log(headers);

            const request = this.http.get(path, { headers: headers })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                    //    console.log(error);
                        observer.error(error);
                        this.showLoader(false)
                    }
                )
        });
    }

    public delete(path) {
        return new Observable(observer => {
            const request = this.http.delete(path, { headers: this.getHeader() })
                .subscribe(
                    repsonse => observer.next(repsonse),
                    error => observer.error(error)
                )
        });
    }

    public userLogin(path, contact) {
        // debugger
        return new Observable(observer => {
            this.http.post(path, contact)
                .subscribe(
                    repsonse => { observer.next(repsonse); },
                    error => observer.error(error)
                );
        });
    }

    public getHeader() {
        let token: any = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : '';
        const headers: any = new HttpHeaders({ 'Authorization': 'Bearer ' + token });
        return headers;
    }

    showLoader(showLoader: boolean) {
        // document.getElementById('loading').style.display = showLoader ? 'block' : 'none';
    }

    public makeFileRequest(path, payload, formName): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        return Observable.create(observer => {
            let formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            let url = path;

            if (formName === 'addAed') {
                this.getFormData(formData, payload, false);
            }

            if (formName === 'addLibrary') {
                this.getFormData(formData, payload, false, '', true);
                this.allow_null_or_empty = ['is_master'];
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    this.showLoader(false);
                    if (xhr.status === 200) {
                        // document.getElementById("spinLoader").style.display='none';                       
                        observer.next(xhr.response);
                        observer.complete(xhr.response);
                    } else {
                        let result = JSON.parse(xhr.response)
                        observer.error(xhr.response);
                        this.showError(result.message);
                    }
                }
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', '*/*');
            let token: any = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : '';
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            xhr.send(formData);
        });
    }


    showError(msg) {
        const toast = document.getElementById('toast-container');
        if (toast) {
            toast.innerHTML = '';
        }
        this.toastr.error(msg, '', {
            // timeOut: 1500
        });
        this.apiResponse = true;
    }

    public downloadPost(path, payload): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        return new Observable(observer => {
            this.xhr = this.http.post(path, payload, { headers: this.getHeader(), responseType: 'blob', observe: 'response' })
            .subscribe(
                response => {
                    observer.next(response);
                    this.showLoader(false);
                },
                error => {
                    // let result =JSON.parse(error)
                    observer.error(error);
                    this.showLoader(false);
                    this.toastr.error(error['error'].message, 'Error');
                    // this.showError(error.error.message);
                }
                );
        });
    }

    public downloadGet(path): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        return new Observable(observer => {
            this.xhr = this.http.get(path, { headers: this.getHeader(), responseType: 'blob', observe: 'response' })
            .subscribe(
                response => {
                    observer.next(response);
                    this.showLoader(false);
                },
                error => {
                    // let result =JSON.parse(error)
                    observer.error(error);
                    this.showLoader(false);
                    this.toastr.error(error['error'].message, 'Error');
                    // this.showError(error.error.message);
                }
                );
        });
    }

    // formData - instance of FormData object
    // data - object to post
    getFormData(formData, data, previousKey, nKey = '', isLibrary = false) {
        if (data instanceof Object) {
            Object.keys(data).forEach(key => {
                const value = data[key];
                if (value instanceof Object && !Array.isArray(value) && this.file_keys.indexOf(key) === -1) {
                    if (previousKey)
                        return this.getFormData(formData, value, previousKey, key);
                    else
                        return this.getFormData(formData, value, key);
                }
                if (previousKey && nKey) {
                    key = `${previousKey}[${nKey}][${key}]`;
                }
                else if (previousKey) {
                    key = `${previousKey}[${key}]`;
                }
                if (Array.isArray(value)) {
                    value.forEach((val, i) => {
                        if (val instanceof Object) {
                            return this.getFormData(formData, val, `${key}[${i}]`);
                        } else
                            formData.append(`${key}[]`, val);
                    });
                } else {
                    if (this.file_keys.indexOf(key) === -1) {
                        if (value || this.allow_null_or_empty.indexOf(key) !== -1)
                            formData.append(key, value);
                    } else { // if file
                        if (isLibrary) {
                            if (value) {
                                formData.append(key, value);
                            }
                        } else {
                            if (value && value.files && value.files[0]) {
                                formData.append(key, value.files[0]);
                            }
                        }
                    }
                }
            });
        }
    }
    date(issueTimeStamp) {
        let i_date = new Date(issueTimeStamp * 1000);
        let startDate = ("0" + (i_date.getMonth() + 1)).slice(-2) + '/' + i_date.getDate() + '/' + i_date.getFullYear();
        return startDate;
    }

    public getPdf(path) {
        this.showLoader(true);
        this.apiResponse = false;
        return new Observable(observer => {
            const request = this.http.get(path, { headers: this.getHeader(), responseType: 'blob', observe: 'response' })
                .subscribe(
                    repsonse => {
                        this.showLoader(false);
                        observer.next(repsonse);
                    },
                    error => {
                        var returnError:any;
                        var blob = new Blob([error.error], { type: 'application/json' ,endings:'native'});
                        
                        const reader = new FileReader();

                        // This fires after the blob has been read/loaded.
                        reader.addEventListener('loadend', (e) => {
                            returnError = e.srcElement['result'] || false;
                            if (returnError) {
                                returnError = JSON.parse(returnError);
                            }

                            observer.error(returnError);

                            this.showLoader(false);
                            if (returnError) {
                                this.showError(returnError.message);
                            } else {
                                this.showError('Unknown error occured.');
                            }
                        });

                        // Start reading the blob as text.
                        reader.readAsText(blob);
                    }
                );
        });
    }

}
