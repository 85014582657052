import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpUtilsService } from '../utils/http-utils.service';
import * as globals from '../../../../../global';
import { BehaviorSubject } from 'rxjs';
import { Observable } from "rxjs";
import { base_url } from "../../../../../global";

@Injectable()
export class ProfileService {
    public xhr = null;
    public apiResponse: boolean = true;
    public cancelRequest() {
        if (this.xhr && !this.xhr.closed) {
            this.xhr.unsubscribe();
        }
    }

    API_URL: any = base_url;

    get imageUrl() {
        return this._imageUrl;
    }

    set imageUrl(value) {
        this._imageUrl = value;
    }
    constructor(private http: HttpClient, private httpUtils: HttpUtilsService) { }

    public listuserrequests() {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http
            .post(this.API_URL + 'user-request/list', {
                "page": 1
            }, { headers: headers }
            );
    }

    public listuserrequestsfordrop() {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'user-request/list',
            {},
            { headers: headers })
    }

    public viewrequests(id) {
        let formData = {
            user_id: id
        };
        return this.http.post(this.API_URL + 'user-request/view', formData);
    }
    public viewuserdata(id) {
        let formData = {
            user_id: id
        };
        return this.http.post(this.API_URL + 'user/view', formData);
    }

    public acceptrequest(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
       // console.log(formData);
        return this.http.post(this.API_URL + 'user-request/accept',
            formData, { headers: headers });
    }


    public rejectrequest(rejectlist) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'user-request/reject',
            rejectlist, { headers: headers });
    }

    //   public manageremoveaed(params) {
    // 		const headers = this.httpUtils.getHTTPHeaders();
    // 		return this.http.post(this.API_URL+'aed/remove-request/list',
    // 		{
    //            params  
    // 		}, {headers: headers});
    //     }

    public manageremoveaed(params): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        const headers = this.httpUtils.getHTTPHeaders();
        return new Observable(observer => {
            //  this.http.post(this.API_URL + 'user/list', params, {headers: headers})
            this.xhr = this.http.post(this.API_URL + 'aed/remove-request/list', params, { headers: headers })
                .subscribe(
                    response => {
                    //    console.log('response', response);
                        observer.next(response);
                        this.showLoader(false);
                    });
        });
    }

    public deleteremoveaedrequest(id) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'aed/remove-request/delete',
            {
                id: id
            }, { headers: headers });
    }

    public viewremoveaedrequest(id) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'aed/remove-request/view',
            {
                id: id
            }, { headers: headers });
    }

    //   public managelibrary(params){
    //       const headers = this.httpUtils.getHTTPHeaders();
    //       return this.http.post(this.API_URL+'library/list',
    //       {
    //        params
    //       }, {headers:headers});
    //   }

    public managelibrary(params): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        const headers = this.httpUtils.getHTTPHeaders();
        return new Observable(observer => {
            //  this.http.post(this.API_URL + 'library/list', params, {headers: headers})
            this.xhr = this.http.post(this.API_URL + 'library/list', params, { headers: headers })
                .subscribe(
                    response => {
                       // console.log('response', response);
                        observer.next(response);
                        this.showLoader(false);
                    });
        });
    }

    public deletelibrary(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'library/delete',
            formData
            , { headers: headers })
    }

    public viewlibrary(id) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'library/view', {
            library_id: id
        }, { headers: headers })
    }


    public updateformData(form): Observable<any> {
        const headers = this.httpUtils.getHTTPHeaders();
        let formData: FormData = new FormData();
        //   formData.append('file', image);
        formData.append('title', form.title);
        formData.append('file', form.file);
        formData.append('library_id', form.library_id)
        return this.http.post(this.API_URL + 'library/edit',
            formData
            , { headers: headers })
    }


    public makeFileRequestForFormData(form): Observable<any> {

        const headers = this.httpUtils.getHTTPHeaders();
        let formData: FormData = new FormData();
        //   formData.append('file', image);
        formData.append('title', form.title);
        formData.append('file', form.file);
        formData.append('is_master', form.is_master);
        formData.append('companies', JSON.stringify(form.company));
        return this.http.post(this.API_URL + 'library/edit',
            formData
            , { headers: headers })
    }

    public makeFileForeditFormData(form): Observable<any> {

        const headers = this.httpUtils.getHTTPHeaders();
        let formData: FormData = new FormData();
        //   formData.append('file', image);
        formData.append('library_id', form.library_id);
        formData.append('title', form.title);
        if (form.file && form.file !== undefined) {
            formData.append('file', form.file);
        }
        return this.http.post(this.API_URL + 'library/edit',
            formData
            , { headers: headers })
    }

    public makeFormDataAddCompany(form): Observable<any> {
        const headers = this.httpUtils.getHTTPHeaders();
        let formData: FormData = new FormData();
        formData.append('company_code', form.company_code);
        formData.append('company_name', form.company_name);
        formData.append('company_phone', form.company_phone);
        formData.append('company_email', form.company_email);
        formData.append('company_logo', form.company_logo);
        formData.append('redirect_store', form.redirect_store);
        return this.http.post(this.API_URL + 'company/add',
            formData
            , { headers: headers })
    }

    public makeFormDataupdateCompany(form): Observable<any> {
        const headers = this.httpUtils.getHTTPHeaders();
        let formData: FormData = new FormData();
        formData.append('company_code', form.company_code);
        formData.append('company_name', form.company_name);
        formData.append('company_phone', form.company_phone);
        formData.append('company_email', form.company_email);
        formData.append('redirect_store', form.redirect_store);
        if (form.company_logo && form.company_logo !== undefined) {
            formData.append('company_logo', form.company_logo);
        }
        formData.append('company_id', form.company_id);
        return this.http.post(this.API_URL + 'company/edit',
            formData
            , { headers: headers })
    }

    public viewcompany(id) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'company/view', {
            company_id: id
        }, { headers: headers })
    }

    // public listmanagecompany(params) {
    //     const headers = this.httpUtils.getHTTPHeaders();
    //     return this.http.post(this.API_URL+'company/list',
    //     {
    //     params
    //     },{headers:headers})
    //   }
    // public listmanagecompany() {
    //     const headers = this.httpUtils.getHTTPHeaders();
    //     return this.http.post(this.API_URL+'company/list',
    //     {
    //         // "page": 1,
    //         // "show_all": 0,
    //         // "search": "",
    //         // "new_also": ""

    //     },{headers:headers})
    //   }
    public viewSite(id) {
        let formData = {
            site_id: id
        };
        return this.http.post(this.API_URL + 'site/view', formData);
    }
    // public manageremoveaedview(id) {
    // 	const headers = this.httpUtils.getHTTPHeaders();
    // 	return this.http.post(this.API_URL+'aed/remove-request/list',
    // 	{
    //         id: id
    // 	}, {headers: headers});
    // }

    public listmanagecompany(params): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        const headers = this.httpUtils.getHTTPHeaders();
        return new Observable(observer => {
            //  this.http.post(this.API_URL + 'user/list', params, {headers: headers})
            this.xhr = this.http.post(this.API_URL + 'company/list', params, { headers: headers })
                .subscribe(
                    response => {
                     //   console.log('response', response);
                        observer.next(response);
                        this.showLoader(false);
                    });
        });
    }

    public listPlan(): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        const headers = this.httpUtils.getHTTPHeaders();
        return new Observable(observer => {
            //  this.http.post(this.API_URL + 'user/list', params, {headers: headers})
            this.xhr = this.http.get(this.API_URL + 'subscription/available-plans', { headers: headers })
                .subscribe(
                    response => {
                     //   console.log('response', response);
                        observer.next(response);
                        this.showLoader(false);
                    });
        });
    }

    public deletecompany(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'company/delete',
            formData
            , { headers: headers })
    }


    // public getManageAdminUserList(params) {
    //     const headers =this.httpUtils.getHTTPHeaders();
    //     return this.http.post(this.API_URL+'admin/list', params,{headers:headers})
    // }


    public getManageAdminUserList(params): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        const headers = this.httpUtils.getHTTPHeaders();
        return new Observable(observer => {
            //  this.http.post(this.API_URL + 'admin/list', params, {headers: headers})
            this.xhr = this.http.post(this.API_URL + 'admin/list', params, { headers: headers })
                .subscribe(
                    response => {
                    //    console.log('response', response);
                        observer.next(response);
                        this.showLoader(false);
                    });
        });
    }

    public viewadminuser(id) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'admin/view', {
            user_id: id
        }, { headers: headers })
    }

    public addadminuser(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'admin/edit', formData, { headers: headers })
    }

    public deleteadminuser(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'admin/delete',
            formData
            , { headers: headers })
    }

    // public listmasteraedmake(params) {
    //     const headers =this.httpUtils.getHTTPHeaders();
    //     return this.http.post(this.API_URL+'master/make/list',
    //     params
    //     ,{headers:headers})
    // }

    public listmasteraedmake(params): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        const headers = this.httpUtils.getHTTPHeaders();
        return new Observable(observer => {
            //  this.http.post(this.API_URL + 'user/list', params, {headers: headers})
            this.xhr = this.http.post(this.API_URL + 'master/make/list', params, { headers: headers })
                .subscribe(
                    response => {
                   //     console.log('response', response);
                        observer.next(response);
                        this.showLoader(false);
                    });
        });
    }

    public listmasteraedmakefordrop() {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/make/list',
            {},
            { headers: headers })
    }

    public deletemasteraedmake(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/make/delete',
            formData,
            { headers: headers })
    }

    public editmasteraedmake(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/make/update', formData, { headers: headers })
    }

    public viewmasteraedmake(id) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/make/view', {
            make_id: id
        }, { headers: headers })
    }

    public addmasteraedmake(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/make/add', formData, { headers: headers })
    }

    // public listmastermodel(params) {
    //    const headers = this.httpUtils.getHTTPHeaders();
    //    return this.http.post(this.API_URL+'master/model/list',params,{headers:headers})
    // }

    public listmastermodel(params): Observable<any> {
        this.showLoader(true);
        this.apiResponse = false;
        const headers = this.httpUtils.getHTTPHeaders();
        return new Observable(observer => {
            this.xhr = this.http.post(this.API_URL + 'master/model/list', params, { headers: headers })
                .subscribe(
                    response => {
                  //      console.log('response', response);
                        observer.next(response);
                        this.showLoader(false);
                    });
        });
    }

    public addmastermodel(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/model/add', formData, { headers: headers })
    }

    public viewmasteraedmodel(id) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/model/view', {
            model_id: id
        }, { headers: headers })
    }

    public deletemasteraedmodel(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/model/delete',
            formData,
            { headers: headers })
    }

    public editmasteraedmodel(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'master/model/update', formData, { headers: headers })
    }


    showLoader(showLoader: boolean) {
        // document.getElementById('loading').style.display = showLoader ? 'block' : 'none';
    }


    // getAllBanners(pageNo, pageSize) {
    //     const headers = this.httpUtils.getHTTPHeaders();
    //     return this.http.get(globals.base_url + `getBannerlist/${pageNo}/${pageSize}`);
    // }

    // changeBannerStatus(formData) {
    //     return this.http.put(globals.base_url + 'bannerStatus', formData);
    // }

    // deleteBanners(formData) {
    //     return this.http.post(globals.base_url + 'deleteBanner', formData);
    // }

    public _image: BehaviorSubject<any> = new BehaviorSubject(null);
    setImage(value) {
        if (value) {
            this._image.next(value);
        } else {
            this._image.next(null);
        }
    }

    public _email: BehaviorSubject<any> = new BehaviorSubject(null);
    setEmail(value) {
        if (value) {
            this._email.next(value);
        } else {
            this._email.next(null);
        }
    }

    public _name: BehaviorSubject<any> = new BehaviorSubject(null);
    setName(value) {
        if (value) {
            this._name.next(value);
        } else {
            this._name.next(null);
        }
    }

    private _imageUrl;

    updateProfile(formData) {
        const headers = new HttpHeaders();
        // headers.set('Content-Type', undefined);
        return this.http.post(globals.updateProfile, formData);
    }

    updatePassword(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(globals.changePassword, formData, { headers: headers });
    }

    getProfile() {
        const headers = this.httpUtils.getHTTPHeaders();
        let user_id: string = <string>localStorage.getItem('loginId');
        if (user_id === '' || user_id === undefined || user_id == null)
            user_id = '71';
        return this.http.post(globals.getProfile, { headers: headers });
    }

    getUdo() {
        return this.http.get(globals.getOdo);
    }

    getMakeList() {
        return this.http.get(globals.getMakeList);
    }

    getCategories() {
        return this.http.get(globals.carPreference);
    }

    getCities() {
        return this.http.get(globals.getCities);
    }

    public addMediaNote(formData) {
        const headers = this.httpUtils.getHTTPHeaders();

        return this.http.post(this.API_URL + 'company/note/add',
            formData, { headers: headers });
    }

    public getNotesByCompany(id, page) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'company/note/list', {
            company_id: id, page: page
        }, { headers: headers })
    }

    public addSubscription(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'subscription/add',
            formData, { headers: headers });
    }
    public updateSubscription(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'subscription/edit',
            formData, { headers: headers });
    }

    public deleteMediaNote(formData) {
        const headers = this.httpUtils.getHTTPHeaders();

        return this.http.post(this.API_URL + 'company/note/delete',
            formData, { headers: headers });
    }

    public deleteAED(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'aed/delete',
            formData,
            { headers: headers })
    }

    public addUserManual(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'user_manual/add',
            formData, { headers: headers });
    }

    public updateUserManual(formData) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(this.API_URL + 'user_manual/update',
            formData, { headers: headers });
    }

}
