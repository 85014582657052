import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable, empty } from 'rxjs';

import { TokenStorage } from '../auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AddAuthorizationInterceptor implements HttpInterceptor {

  constructor(private tokenStorate: TokenStorage) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    let authToken = '';
    this.tokenStorate.getAccessToken().subscribe((token) => {
      authToken = token;
    });
    const setheader = req.headers.get('Anonymous');
    if (setheader && authToken) {
      const newheader = req.headers.delete('Anonymous')
      const newRequest = req.clone({ headers: newheader });
      return next.handle(newRequest);
    }
    if (authToken) {
      const clonedRequest = req.clone({ headers: req.headers.set('Authorization', authToken) });
      return next.handle(clonedRequest);
    }

    return next.handle(req);
    {

    }
  }
}
