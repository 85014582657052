// tslint:disable-next-line:no-shadowed-variable
import { ConfigModel } from '../core/interfaces/config';

// tslint:disable-next-line:no-shadowed-variable


export class MenuConfig implements ConfigModel {
    public config: any = {};
    displayItems = JSON.parse(localStorage.getItem('permissions'))

    constructor() {
        let stageCount = localStorage.getItem('stageCount');
        let partExpiringCount = localStorage.getItem('partsExpiringCount');
        let partExpiredCount = localStorage.getItem('partExpiredCount');

        this.config = {
            header: {
                self: {},
                items: [
                ]
            },
            aside: {
                self: {},
                items: [{
                    title: 'Dashboard',
                    desc: 'dashboard',
                    root: true,
                    icon: 'fas fa-chart-line',
                    page: '/dashboard',
                },
            ]
            }
        };
        this.config.aside.items.push({
            title: 'Plans',
            desc: 'AED listing, details and action',
            root: true,
            icon: 'fas fa-shopping-bag',
            page: '/subscription-list',
        },
        )
        if (this.displayItems && this.displayItems.add_company) {
            this.config.aside.items.push({
                title: 'Company',
                desc: 'Users listing, details and action',
                root: true,
                icon: 'fas fa-building',
                page: '/manage-company',

            })
        }
        if (this.displayItems && this.displayItems.aed_sites) {
            this.config.aside.items.push({
                title: 'Manage Sites ',
                desc: 'Users listing, details and action',
                root: true,
                icon: 'fa fa-building',
                page: '/manage-site',
            })
        }
        if (this.displayItems && this.displayItems.add_user) {
            this.config.aside.items.push({
                title: 'User Management',
                desc: 'Users listing, details and action',
                root: true,
                icon: 'fa fa-user',
                page: '/manage-user',
            })
        }
        if (this.displayItems && this.displayItems.global_inspector) {
            this.config.aside.items.push({
                title: 'Global Inspector',
                desc: 'Global Inspector listing, details and action',
                root: true,
                icon: 'fa fa-user',
                page: '/manage-global-inspector',
            })
        }
        if (this.displayItems && this.displayItems.add_responders) {
            this.config.aside.items.push({
                title: 'Responder ',
                desc: 'Users listing, details and action',
                root: true,
                icon: 'fas fa-reply',
                page: '/manage-responder',
            })
        }
        if (this.displayItems && this.displayItems.manage_aed) {
            this.config.aside.items.push({
                title: 'AED',
                desc: 'AED listing, details and action',
                root: true,
                icon: 'fa fa-heartbeat',
                page: '/manage-aed',
            })
        }
        if (this.displayItems && this.displayItems.approve_aed) {
            this.config.aside.items.push({
                title: 'AED Requests',
                desc: 'Users listing, details and action',
                root: true,
                icon: 'fa fa-random',
                page: '/aed-request',

            })
        }
        if (this.displayItems && this.displayItems.remove_aed) {
            this.config.aside.items.push({
                title: 'Remove AED Request',
                desc: 'Users listing, details and action',
                root: true,
                icon: 'fas fa-user-minus',
                page: '/manage-remove-aed-request',

            })
        }
        if (this.displayItems && this.displayItems.request_replacement) {
            this.config.aside.items.push({
                title: 'Staging Area',
                desc: 'Accessory replacement request list, details and action',
                root: true,
                icon: 'fas fa-sync',
                page: '/accessory-replacement-request',
                value: stageCount,
            })
        }
        this.config.aside.items.push(
            {
                title: 'Parts Expiring Soon',
                desc: 'Parts Expiring',
                root: true,                      
                icon: 'fas fa-battery-quarter',
                page: '/parts-expiring',      
                value: partExpiringCount,                 
            }
        )
        if(this.displayItems && this.displayItems.post_library){
            this.config.aside.items.push(
            {
                title: 'Parts Expired',
                desc: 'Parts Expired',
                root: true,                      
                icon: 'fas fa-calendar-times',
                page: '/parts-expired',  
                value: partExpiredCount, 
            }
            )
        }
        if (this.displayItems && this.displayItems.add_user && this.displayItems.add_company) {
            this.config.aside.items.push({
                title: 'Manage Free Trials',
                desc: 'manage free trials ,details and action',
                root: true,
                icon: 'fab fa-sistrix',
                page: '/manage-free-trials',
            })
        }
        if (this.displayItems && this.displayItems.add_support_user) {
            this.config.aside.items.push({
                title: 'Manage Support Users',
                desc: 'Reseller listing, details and action',
                root: true,
                icon: 'fas fa-tasks',
                page: '/manage-support-users',
            })
        }
        if (this.displayItems && this.displayItems.add_support_user) {
            this.config.aside.items.push({
                title: 'User Manual',
                desc: 'User Manual',
                root: true,
                icon: 'fa fa-upload',
                page: '/user-manual-list',
            })
        }
        if (this.displayItems && this.displayItems.post_library) {
            this.config.aside.items.push({
                title: 'Library',
                desc: 'Users listing, details and action',
                root: true,
                icon: 'fas fa-book-open',
                page: '/manage-library',

            })
        }
        if (this.displayItems && this.displayItems.post_library) {
            this.config.aside.items.push({
                title: 'Trash Bin',
                desc: 'AED listing, details and action',
                root: true,
                icon: 'fa fa-trash',
                page: '/trash-bin',
            })
        }

        this.config.aside.items.push({
            title: 'Master Model ',
            desc: 'Users listing, details and action',
            root: true,
            icon: 'fas fa-hotel',
            page: '/master-model',
        })
        
        if(this.displayItems && this.displayItems.post_library){
            this.config.aside.items.push(
            {
                title: 'Incidents',
                desc: 'Incidents',
                root: true,                      
                icon: 'far fa-calendar-alt',
                page: '/incidents',                       
            })
            this.config.aside.items.push(
            {
                title: 'Reports',
                desc: 'Reports',
                root: true,                      
                icon: 'fas fa-file-alt',
                page: '/reports',                       
            })
            this.config.aside.items.push(
            {
                title: 'Inspections',
                desc: 'Inspections',
                root: true,                      
                icon: 'fas fa-search',
                page: '/inspection',

            })
            this.config.aside.items.push(
                {
                    title: 'Manage Renew Report',
                    desc: 'Manage Renew Report',
                    root: true,                      
                    icon: 'fas fa-file-alt',
                    page: '/manage-renew-report',
    
                })
        }
    }
}
