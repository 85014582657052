import { Observable, Subject, from, throwError } from 'rxjs';
import { map, catchError, tap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'ngx-auth';

import { TokenStorage } from './token-storage.service';
import { UtilsService } from '../services/utils.service';
import { AccessData } from './access-data';
import { Credential } from './credential';
import { AuthUrlsService } from '../services/global-urls/auth-urls.service';
import { AdminProfileModel } from '../services/state-data/admin-profile.model';
import { ToastrService } from 'ngx-toastr';
import * as globals from '../../global';
import { HttpUtilsService } from '../../../app/content/pages/components/_core/utils/http-utils.service';
import { environment } from "../../../environments/environment";
import { base_url, urls } from "../../global";
import { retry } from "rxjs/internal/operators";

@Injectable()
export class AuthenticationService implements AuthService {

    public errorMessage = '';

    // URLs
    API_URL = '';
    API_ADMIN_URL = '';
    API_USER_URL = '';
    API_ADMIN_PANNEL = '';
    API_USER_PANNEL = '';
    API_ENDPOINT_LOGIN = '';
    API_ENDPOINT_REFRESH = '';
    API_ENDPOINT_REGISTER = '';
    API_ENDPOINT_FORGOT_PWD = '';
    API_ENDPOINT_UPDATE_PROFILE = '';
    API_ENDPOINT_GET_PROFILE = '';
    public onCredentialUpdated$: Subject<AccessData>;

    constructor(private http: HttpClient,
        private tokenStorage: TokenStorage,
        private util: UtilsService,
        private authUrls: AuthUrlsService,
        private toastr: ToastrService,
        private httpUtils: HttpUtilsService) {
        this.onCredentialUpdated$ = new Subject();
        this.Init();
    }

    Init() {
        var urls = this.authUrls.getAuthUrls();
        this.API_ADMIN_URL = urls.API_ADMIN_URL;
        this.API_USER_URL = urls.API_USER_URL;
        this.API_ADMIN_PANNEL = urls.API_ADMIN_PANNEL;
        this.API_USER_PANNEL = urls.API_USER_PANNEL;
        this.API_ENDPOINT_LOGIN = urls.API_ENDPOINT_LOGIN;
        this.API_ENDPOINT_REFRESH = urls.API_ENDPOINT_REFRESH;
        this.API_ENDPOINT_FORGOT_PWD = urls.API_ENDPOINT_FORGOT_PWD;
        this.API_ENDPOINT_UPDATE_PROFILE = urls.API_ENDPOINT_UPDATE_PROFILE;
        this.API_ENDPOINT_GET_PROFILE = urls.API_ENDPOINT_GET_PROFILE;
    }

    /**
     * Check, if user already authorized.
     * @description Should return Observable with true or false values
     * @returns {Observable<boolean>}
     * @memberOf AuthService
     */
    public isAuthorized(): Observable<boolean> {
        return this.tokenStorage.getAccessToken().pipe(map(token => !!token));
    }

    /**
     * Get access token
     * @description Should return access token in Observable from e.g. localStorage
     * @returns {Observable<string>}
     */
    public getAccessToken(): Observable<string> {
        return this.tokenStorage.getAccessToken();
    }

    /**
     * Get user roles
     * @returns {Observable<any>}
     */
    public getUserRoles(): Observable<any> {
        return this.tokenStorage.getUserRoles();
    }

    /**
     * Function, that should perform refresh token verifyTokenRequest
     * @description Should be successfully completed so interceptor
     * can execute pending requests or retry original one
     * @returns {Observable<any>}
     */
    public refreshToken(): Observable<AccessData> {
        return this.tokenStorage.getRefreshToken().pipe(
            switchMap((refreshToken: string) => {
                return this.http.get<AccessData>(this.API_URL + this.API_ENDPOINT_REFRESH + '?' + this.util.urlParam(refreshToken));
            }),
            tap(this.saveAccessData.bind(this)),
            catchError(err => {
                this.logout();
                return throwError(err);
            })
        );
    }

    /**
     * Function, checks response of failed request to determine,
     * whether token be refreshed or not.
     * @description Essentialy checks status
     * @param {Response} response
     * @returns {boolean}
     */
    public refreshShouldHappen(response: HttpErrorResponse): boolean {
        return response.status === 401;
    }

    /**
     * Verify that outgoing request is refresh-token,
     * so interceptor won't intercept this request
     * @param {string} url
     * @returns {boolean}
     */
    public verifyTokenRequest(url: string): boolean {
        return url.endsWith(this.API_ENDPOINT_REFRESH);
    }


    public getLoggedInUserId() {
        let user_id: string = <string>localStorage.getItem('loginId');
        if (user_id === '' || user_id === undefined || user_id == null)
            user_id = '71';
        return user_id;
    }

    /**
     * Submit login request
     * @param {Credential} credential
     * @returns {Observable<any>}
     */
    public login(credential): Observable<any> {

        /*let formData = {
            email: credential.email,
            password: credential.hashPassword
        },
            xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    observer.next(xhr.response);
                    observer.complete(xhr.response);
                    //this.spinner.hide();
                } else {
                    observer.error(xhr.response);
                    //   this.spinner.hide();
                }
            }
        };
        console.log('formdata', formData);
        xhr.open('POST', globals.base_url + 'auth/login', true);
        xhr.setRequestHeader('Accept', '*!/!*');
        // xhr.setRequestHeader('Content-Type', 'application/json');
        // xhr.setRequestHeader('Authorization', localStorage.getItem('accessToken'));
        xhr.send(formData);*/
        /*return this.http.post(base_url + `auth/login`,
            {
                email: credential.email,
                password: credential.hashPassword
            })*/
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post<any>(base_url + `auth/login`,
            {
                email: credential.email,
                password: credential.hashPassword
            }, { headers: headers })
            .pipe(
                retry(0),
                map(
                    res => {
                       // console.log('GOT GRAPH DATA FOR MANAGER DASHBOARD => ', res);
                        return res;
                    }
                ),
                catchError((error: HttpErrorResponse) => {
                  //  console.log('ERROR IN MANAGER DASHBOARD DATA => ', error);
                    return throwError(error);
                })
            );
    }

    public verifyAccount(body) {
        const headers = this.httpUtils.getHTTPHeaders();
        return this.http.post(globals.base_url + '/admin/verifyEmail', body, { headers: headers });
    }

    // public getPermissions() {
    //     const headers: any = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` });
    //     console.log(headers)
    //     return this.http.get(urls.userPermission, { headers: headers })
    // }

    public resetPassword(params) {
        // return this.http.post(globals.base_url + 'auth/reset-password', body);
        return this.http.post<any>(base_url + `auth/reset-password`, params)
            .pipe(
                retry(0),
                map(
                    res => {
                        return res;
                    }
                ),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            this.errorMessage = (error);
            this.toastr.error('Something went wrong. Check your internet connection and try again', 'Error');
            // Let the app keep running by returning an empty result.
            return from(result);
        };
    }

    /**
     * Logout
     */
    public logout(refresh?: boolean): void {
        this.tokenStorage.clear();
        localStorage.clear();
        if (!environment.production) {
            window.location.replace('/login');
        } else {
            window.location.replace('/aed365-reseller/login')
        }
        this.tokenStorage.clear();
        if (refresh) {
            location.reload(true);
        }
    }

    /**
     * Save access data in the storage
     * @private
     * @param {AccessData} data
     */
    private saveAccessData(accessData: AccessData) {
        if (typeof accessData !== 'undefined') {
            if (accessData.result) {

                this.tokenStorage
                    .setAccessToken(accessData.result.token)
                    .setRefreshToken(accessData.result.token)
                    .setUserRoles(accessData.result.user.roles.map(String));

                this.onCredentialUpdated$.next(accessData);
            }
        }
    }

    /**
     * Submit registration request
     * @param {Credential} credential
     * @returns {Observable<any>}
     */
    public register(credential: Credential): Observable<any> {
        // dummy token creation
        credential = Object.assign({}, credential, {
            accessToken: 'access-token-' + Math.random(),
            refreshToken: 'access-token-' + Math.random(),
            roles: ['USER'],
        });
        return this.http.post(this.API_URL + this.API_ENDPOINT_REGISTER, credential)
            .pipe(catchError(this.handleError('register', []))
            );
    }

    /**
     * Submit forgot password request
     * @param {Credential} credential
     * @returns {Observable<any>}
     */
    public requestPassword(credential: Credential): Observable<any> {
        return this.http.post<any>(base_url + `auth/forgot-password`, {
            email: credential.email
        })
            .pipe(
                retry(0),
                map(
                    res => {
                        return res;
                    }
                ),
                catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                })
            );
    }

    /*public requestPassword(credential: Credential): Observable<any> {
     return this.http.post(globals.base_url + 'auth/forgot-password' , {
     email : credential.email
     })
     }*/


    /**
     * Submit update profile request
     * @returns {Observable<any>}
     */
    public getProfile(userInfo: AdminProfileModel): Observable<any> {
        let user_id = this.getLoggedInUserId();

        return this.http.get<AccessData>(this.API_ADMIN_URL + this.API_ADMIN_PANNEL + this.API_ENDPOINT_GET_PROFILE + '?userId=' + user_id).pipe(
            map((result: any) => {
                if (result.error) {
                    return result;
                }
                if (result instanceof Array) {
                    return result.pop();
                }
                return result;
            })
        );
    }
}
