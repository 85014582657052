import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { urls } from '../../../../global';
import { HttpUtilsService } from '../../components/_core/utils/http-utils.service';


@Component({
  selector: 'm-login-as',
  templateUrl: './login-as.component.html',
  styleUrls: ['./login-as.component.scss']
})
export class LoginAsComponent implements OnInit {
  token: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.token = params['logintoken'];
      const isAdmin = params['isAdmin']
      localStorage.setItem('accessToken', this.token);
      localStorage.setItem("loginId", isAdmin);

    });
    this.getPageInfo()

  }

  getPageInfo() {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // })
    this.http.get(urls.userPermission, { headers: { 'Anonymous': 'true', 'Content-Type': 'application/json' } }).subscribe(
      (response: any) => {
        if (!response.success) {
          this.toastr.error(response.errors[0].message, 'Error');
        }
        else if (response.success && response.success_code == 200) {
          if (response.data.user) {
            let userData = {
              'email': response.data.user.email,
              'name': response.data.user.name,
              'image': response.data.user.image_url
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("reseller_code", response.data.user.reseller_code)
            localStorage.setItem("company_name", response.data.user.company_name)
          }
          if (response.data.permissions) {
            localStorage.setItem("permissions", JSON.stringify(response.data.permissions))
          }
          this.router.navigate(['/dashboard']);
        }

        this.cdr.detectChanges();
      }, error => {
        this.toastr.error(error.error.message, '');
        this.cdr.detectChanges();
      });
  }

}
